import { getMonths, getYears } from '../../../services/date-service';

import { AppRow } from '../../../components/app-grid';
import { AppCta } from '../../../components/app-cta';

export function PanelSelectMonth({ onSelect, curentMonth }) {
  return (
    <div className="app-panel__select__date is-month">
      <AppRow spaceBetween>
        <label>
          Month
        </label>
      </AppRow>
      <AppRow
        center
        className="app-panel__select__date-list">
        {getMonths().map(month => (
          <AppCta
            key={month}
            onClick={() => onSelect({ month })}
            active={curentMonth === month}
            type="secondary"
            size="medium"
            muted>
            <span>{month}</span>
          </AppCta>
        ))}
      </AppRow>
    </div>
  );
}

export function PanelSelectYear({ onSelect, curentYear }) {
  return (
    <div className="app-panel__select__date is-year">
      <AppRow spaceBetween>
        <label>
          Year
        </label>
      </AppRow>
      <AppRow
        center
        className="app-panel__select__date-list">
        {getYears().map(year => (
          <AppCta
            key={year}
            onClick={() => onSelect({ year })}
            active={curentYear === year}
            type="secondary"
            size="medium"
            muted>
            <span>{year}</span>
          </AppCta>
        ))}
      </AppRow>
    </div>
  );
}
