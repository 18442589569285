import { useState  } from 'react';
import { ReactComponent as IconPathSvg } from '../../../components/app-svg/icon-path.svg';

import { AppRow } from '../../../components/app-grid';
import { AppTogglePanel } from '../../../components/app-toggle-panel';
import { AppCta } from '../../../components/app-cta';
import { AppCardCertificate } from '../../../components/app-card';
import { FormCardTransfert } from './FormCardTransfert';

import { useEffect } from 'react';
import {useAccount, useContractRead, useContractInfiniteReads , paginatedIndexesConfig} from 'wagmi';
import {COLLECTION_ADDRESS, COLLECTION_ABI} from '../../../contracts/Contracts';

export default SectionTransfert;

function SectionTransfert(props) {
  const {address, isConnected}  = useAccount()
  const [addr_abr, setAddr_abr] = useState('');
  //const [certificates, setCertificates] = useState([]);
  if(!isConnected || address === undefined){
    console.log("Disconnected!")
    window.location = "/"
  } 
  
  useEffect(() => {
    if(isConnected) setAddr_abr( address.substring(0,6).concat("...").concat(address.substring(address.length - 4)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const owners  = useContractInfiniteReads({cacheKey:"owner",
      ...paginatedIndexesConfig(
          (index) => ({address: COLLECTION_ADDRESS, abi: COLLECTION_ABI, functionName: 'ownerOf', args: [index], 
          }),
          { start: 1, perPage: parseInt(props.numCertificates), direction: 'increment' },
      ),
      onSuccess(data) {
        //console.log('Reading BALANCES Success', data)
      },
      suspense: true,
      cacheTime: 3000,
      watch: true,
  })
  
  const details  = useContractInfiniteReads({cacheKey:"detail",
    ...paginatedIndexesConfig(
        (index2) => ({address: COLLECTION_ADDRESS, abi: COLLECTION_ABI, functionName: 'getCertificateDetails', args: [index2], watch: true,
        }),
        { start: 1, perPage: parseInt(props.numCertificates), direction: 'increment' },
    ),
    onSuccess(data) {
      //console.log('Reading CERTIFICATES DETAILS Success', data)
    },
    suspense: true,
    cacheTime: 3000,
    watch: true,
  })
        
  let myCertificates2 = []

  if(owners.isSuccess && details.isSuccess) {
          if(owners.data.pages[0].includes(address.toString())){
              for(let j = 0; j < owners.data.pages[0].length; j++){
                  if(details.data.pages[0][j] && owners.data.pages[0][j].toString() === address.toString()){ 
                      //console.log("------  You are the owner of the nft ID "+parseInt(j+1))
                                            
                      var dateExp = new Date(details.data.pages[0][j][0] * 1000);
                      var monthsExp = dateExp.getMonth();
                      monthsExp += 1
                      if(monthsExp < 10) monthsExp = '0'+monthsExp
                      var yearExp = dateExp.getFullYear().toString();
                      var formattedTimeExp = monthsExp + '/' + yearExp.substring(yearExp.length - 2);

                      var dateMint = new Date(details.data.pages[0][j][1] * 1000);
                      var monthsMint = dateMint.getMonth();
                      monthsMint += 1
                      if(monthsMint < 10) monthsMint = '0'+monthsMint
                      var yearMint = dateMint.getFullYear().toString();
                      var formattedTimeMint = monthsMint + '/' + yearMint.substring(yearMint.length - 2);

                      myCertificates2.push(
                        {
                          "id": parseInt(j+1),
                          "timeExpiry": formattedTimeExp,
                          "awardDate": formattedTimeMint,
                          "type": details.data.pages[0][j][2],
                          "firstName": details.data.pages[0][j][3],
                          "lastName": details.data.pages[0][j][4],
                          "signatories": details.data.pages[0][j][5],
                        }
                      )
                      
                  }
              }
              
          }
          
    }

  return (
    
   
    <section className={`app-page-logged__section app-section__transfert`}>
      <AppRow middle spaceBetween>
        <p>
          Certificates in your Wallet
        </p>
        <AppCta name="token" type='custom' iconRight>
          {addr_abr}<span/>
        </AppCta>
      </AppRow>
      
      <AppRow column center>
        {myCertificates2.length === 0 ? 
        <p style={{fontWeight:"normal", fontFamily:"Montserrat"}}>No certificate in this wallet.</p>
        :
        myCertificates2.map((index) => 
          <AppCardCertificate key={index['id']} id={index['id']} type={index['type']} firstName={index['firstName']} lastName={index['lastName']} timeExpiry={index['timeExpiry']} timeMint={index['awardDate']}>
          <AppTogglePanel
            renderCta={(onToggle) => (
            <AppCta
              className="app-cta__card-transfert"
              name="transfer"
              type="custom"
              iconLeft
              onClick={onToggle}>
              <IconPathSvg />
              Transfer
            </AppCta>
          )}
          renderPanelContent={((onClose) => <FormCardTransfert onClose={onClose} certificateID={index['id'] } />)} />
        </AppCardCertificate>
        )}
        
      </AppRow>
      
    </section>
  
  );
}
