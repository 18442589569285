import { useEffect, useState } from 'react';
import { getCurrentYear, getCurrentMonth } from '../../../services/date-service';

import { ReactComponent as IconChevronDownSvg } from '../../../components/app-svg/icon-chevron-down.svg';

import { AppTogglePanel } from '../../../components/app-toggle-panel';
import { AppRow, AppCol } from '../../../components/app-grid';
import { AppCta } from '../../../components/app-cta';
import { PanelSelectMonth, PanelSelectYear } from './../common/PanelSelectDate';

import {useContractWrite, usePrepareContractWrite , useWaitForTransaction} from 'wagmi';
import {COLLECTION_ADDRESS, COLLECTION_ABI} from '../../../contracts/Contracts';
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';

const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];

const EditButton = (props) => {
  const [confirmed, setConfirmed] = useState(false);
  const [intervalId, setIntervalId] = useState(-1);
  const dateStr = props.expireDate.year+'-'+props.expireDate.month+'-1';
  const date = new Date(dateStr);
  const timestampInMs = date.getTime();
  const unixTimestamp = Math.floor(date.getTime() / 1000);

  const addRecentTransaction = useAddRecentTransaction();
  
  const { config }  = usePrepareContractWrite({
    address: COLLECTION_ADDRESS,
    abi: COLLECTION_ABI,
    functionName: '_editCertificate',
    args: [props.certificateId, unixTimestamp, props.firstName, props.lastName],
   
  })
  const { data, isError, isLoading, isSuccess, write }  = useContractWrite(config)
  
  const waitForTransaction  = useWaitForTransaction({
    hash: data?.hash,
  })
  
  if(waitForTransaction.isLoading && intervalId === -1){
    let dots = ""
    setConfirmed(false)
    setIntervalId(setInterval(function(){
      if(dots === "..."){
        dots = ""
      }else{
        dots += "."
      }

      document.getElementById("buttonEdit"+props.certificateId).innerText = dots+"Loading"+dots
    }, 400)
    )
    
  }

  if(waitForTransaction.isSuccess && !confirmed){
    clearInterval(intervalId)

    
    document.getElementById("expiryDate"+props.certificateId).innerHTML = "Loading..."
    document.getElementById("fullName"+props.certificateId).innerHTML = "Loading..."
    
    setTimeout(function(){
      setConfirmed(true)
    }, 1500)

    setTimeout(function(){
      setIntervalId(-1)
      props.onClose()
    }, 3000)
    
  }
  
  let disabled = props.firstName === '' || props.lastName === '' || props.expireDate.month === "" || props.expireDate.year === ""
  return(
  <AppCta
        onClick={!waitForTransaction.isLoading && !disabled? () => write?.() : undefined}
        name='submit'
        id = {"buttonEdit"+props.certificateId}
        size='large'
        block>
        {waitForTransaction.isLoading? "Loading" : "Confirm"}
  </AppCta>
)}


export function PanelEditCertificate({ reload, onClose, certificateId, type, firstName, lastName, timeExpiry, timeMint }) {
  var monthExpire, yearExpire, monthDate, yearDate;
  monthExpire = timeExpiry.split("/")[0]
  yearExpire = timeExpiry.split("/")[1]
  if(monthExpire[0] === '0') monthExpire = monthExpire.substring(1,2)
  yearExpire = '20'+yearExpire
  monthExpire = months[monthExpire - 1]
  
  const [firstNameInput, setFirstNameInput] = useState('');
  const [lastNameInput, setLastNameInput] = useState('');
  const [expireDate, setExpireDate] = useState({ month: '', year: '' });
  useEffect(() => {
    setFirstNameInput(firstName)
    setLastNameInput(lastName)
    //setExpireDate({month: monthExpire, year:yearExpire})
  }, []);

  useEffect(() => {

    if(firstNameInput === '' || lastNameInput === '' || expireDate.month === '' || expireDate.year === ''){
      //console.log("DISABLING BUTTON")
      let _button = document.getElementById("buttonEdit"+certificateId)
      if(_button){
        _button.style.color = "#cacaca"
        _button.style.backgroundColor = "#e7e7e7"
        _button.disabled = true;
      }
      

    }else{
      //console.log("ENABLING BUTTON")
      let _button = document.getElementById("buttonEdit"+certificateId)
      if(_button){
        _button.style.color = "#fff"
        _button.style.backgroundColor = "#df2127"
        _button.disabled = false;
      }
    }

  }, [firstNameInput, lastNameInput, expireDate.month, expireDate.year]);

  return (
    <form className="app-form__card-edit">
      <AppRow className="app-field--text" left middle>
        <AppCol>
          <label>
            First Name
          </label>
        </AppCol>
        <AppCol>
          <input
            type="text"
            value={firstNameInput}
            onChange={(e) => setFirstNameInput(e.target.value)}/>
        </AppCol>
      </AppRow>

      <AppRow className="app-field--text" left middle>
        <AppCol>
          <label>
            Last Name
          </label>
        </AppCol>
        <AppCol>
          <input
            type="text"
            value={lastNameInput}
            onChange={e => setLastNameInput(e.target.value)}/>
        </AppCol>
      </AppRow>

      <AppRow className="app-field__date" left middle>
        <AppCol>
          <label>
            Expiry Date
          </label>
        </AppCol>
        <AppCol right>
        <AppTogglePanel
        left={'-118px'}
            renderCta={(onToggle) => (
              <AppCta
                className={`app-select app-select__date is-month`}
                muted={expireDate.month === ""}
                type="secondary"
                iconRight
                block
                onClick={onToggle}>
                {expireDate.month ? expireDate.month : getCurrentMonth()}
                <IconChevronDownSvg />
              </AppCta>
            )}
            renderPanelContent={((onClose) => (
              <PanelSelectMonth
                curentMonth={expireDate.month}
                onClose={onClose}
                onSelect={({ month }) => {
                setExpireDate({ ...expireDate, month });
                onClose();
              }} />
            ))}
          />

          <AppTogglePanel
          left={'-252px'}
            renderCta={(onToggle) => (
              <AppCta
                className={`app-select app-select__date is-year`}
                muted={expireDate.year === ""}
                type="secondary"
                iconRight
                block
                onClick={onToggle}>
                {expireDate.year ? expireDate.year : getCurrentYear()}
                <IconChevronDownSvg />
              </AppCta>
            )}
            renderPanelContent={((onClose) => (
              <PanelSelectYear
                curentYear={expireDate.year}
                onClose={onClose}
                onSelect={({ year }) => {
                setExpireDate({ ...expireDate, year });
                onClose();
              }} />
            ))}
          />
        </AppCol>
      </AppRow>

      <EditButton onClose={onClose} certificateId={certificateId} expireDate={expireDate} firstName={firstNameInput} lastName={lastNameInput}/>
    </form>
  );
}
