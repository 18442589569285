import { PageConnect } from './pages/page-connect';
import { PageLogged } from './pages/page-logged';
import { Routes, Route } from 'react-router-dom';

import '@rainbow-me/rainbowkit/styles.css';
import {
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { publicProvider } from 'wagmi/providers/public';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { infuraProvider } from 'wagmi/providers/infura';
import {
  chain,
  configureChains,
  createClient,
  WagmiConfig
} from 'wagmi';
import {polygon} from 'wagmi/chains'
import { connectorsForWallets, lightTheme } from '@rainbow-me/rainbowkit';
import {
  trustWallet,
  metaMaskWallet,
  coinbaseWallet,
  walletConnectWallet,
  ledgerWallet
} from '@rainbow-me/rainbowkit/wallets';

import './App.scss';

export const bscTestnet: Chain = {
  id: 97,
  name: 'BSC Testnet',
  network: 'BSC Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'BNB',
    symbol: 'BNB',
  },
  rpcUrls: {
    default: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    //default : 'https://bsc-testnet.public.blastapi.io'
  },
  blockExplorers: {
    etherscan: { name: 'BNB Scan', url: 'https://testnet.bscscan.com' },
    default: { name: 'BNB Scan', url: 'https://testnet.bscscan.com' },
  },
}

var { chains, provider } = configureChains(
  [ bscTestnet],
  [
    //alchemyProvider({ apiKey: 'D6SKBwgFrh2PIEcsKrOnUUKGIiR_Ewc3' }),
    //infuraProvider({ apiKey: '677a2499edc84df7a41570cd5bb51d06' }),    
    publicProvider()
  ]
);

const connectors = connectorsForWallets([
  {
    groupName: 'Most Used',
    wallets: [ 
      metaMaskWallet({ chains }),
      walletConnectWallet({ chains }),
      trustWallet({chains }),
      coinbaseWallet({ chains, appName: 'MoneyGram NFT Certificates Platform' }),
    ],
  },
  {
    groupName: 'Cold Wallets',
    wallets: [
      ledgerWallet({chains, infurId: "677a2499edc84df7a41570cd5bb51d06"}),
    ],
  },
]); 
const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})

function App() {
  return (

    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} showRecentTransactions={true} theme={lightTheme({
        accentColor: '#DF2127',
        accentColorForeground: 'white',
        borderRadius: 'large',
        fontStack: 'system',
        overlayBlur: 'small',
      })} >

        <Routes> 
          <Route exact path='/' element={<PageConnect />}></Route>
          <Route exact path='/app' element={<PageLogged />}></Route>
        </Routes>

      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;