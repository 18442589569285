//import { useState } from 'react';
import { ReactComponent as IconSuccessSvg } from '../app-svg/icon-success.svg';

export default AppCtaProgress;

function AppCtaProgress({ children, counter = 0 }) {
  if (counter < 1) {
    return children;
  }
  return (
    <div className={_getClassName({ counter })}>
      <SuccesMessage counter={counter} />
      <ProgressMessage counter={counter} />
      <ProgressBar counter={counter} />
    </div>
  );
}

function SuccesMessage({ counter }) {
  if (counter === 100) {
    return (
      <span className='app-cta-progress__message'>
        <IconSuccessSvg /> Minted
      </span>
    )
  }
  return false;
}

function ProgressMessage({ counter }) {
  if (counter < 100) {
    return (
      <span className='app-cta-progress__message'>
        Minting in Progress...
      </span>
    )
  }
  return false;
}

function ProgressBar({ counter }) {
  return (
    <span
    className='app-cta-progress__bar'
    style={{ width: `${counter}%` }} />
  )
}

function _getClassName({ counter }) {
  let value = 'app-cta-progress';
  if (counter === 100) {
    value += ' is-success';
  } else {
    value += ' is-progress';
  }

  return value;
}
