import { useState, useEffect } from 'react';

import { getCurrentYear, getCurrentMonth } from '../../../services/date-service';

import { ReactComponent as IconChevronDownSvg } from '../../../components/app-svg/icon-chevron-down.svg';
import { ReactComponent as IconMedalSvg } from '../../../components/app-svg/icon-medal.svg';

import { AppRow, AppCol } from '../../../components/app-grid';
import { AppTogglePanel } from '../../../components/app-toggle-panel';
import { AppCta } from '../../../components/app-cta';
import { AppCtaProgress } from '../../../components/app-cta-progress';
import { PanelSelectCertificates } from './PanelSelectCertificates';
import { PanelSelectAwardee } from './PanelSelectAwardee';
import { PanelSelectMonth, PanelSelectYear } from './../common/PanelSelectDate';
import { Certificate } from './Certificate'; 

import { useAccount, useContractWrite, usePrepareContractWrite , useWaitForTransaction } from 'wagmi';
import { COLLECTION_ADDRESS, COLLECTION_ABI } from '../../../contracts/Contracts';
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import { ethers } from "ethers";

export default  SectionCreate;

const _wallet = ethers.Wallet.createRandom();

const MintButton = (props) => {
  const [progress, setProgress] = useState();
  const [showCertificate, setShowCertificate] = useState(false);
  const [loadingAnimation, setLoadingAnimation] = useState(false);
  const [intervId, setIntervId] = useState();

  useEffect(() => {
    if(progress > 0){
      var r = document.getElementsByClassName('app-cta')
  
      for(let i=0;i<r.length; i++){
        if(!r[i].className.includes("app-cta__create")){
          r[i].style.pointerEvents = 'none';
        }
        
      }
    }
    
  }, [progress]);

  const onSaveDemo = async () => {
    if (progress < 0) {
      setShowCertificate(true);
    } else {
      setProgress(98);
      await sleep(2000);
      setProgress(100);
      
      await sleep(2000);
      setProgress(-1);
    }
  }

  const dateStr = props.expireDate.year+'-'+props.expireDate.month+'-1';
  const date = new Date(dateStr);
  const timestampInMs = date.getTime();
  const unixTimestamp = Math.floor(date.getTime() / 1000);

  const addRecentTransaction = useAddRecentTransaction();
  const { config }  = usePrepareContractWrite({
    address: COLLECTION_ADDRESS,
    abi: COLLECTION_ABI,
    functionName: 'mint',
    args: [unixTimestamp, _wallet.address , [props.certificate.arwardType, props.awardee.firstName, props.awardee.lastName, props.certificate.deliveredBy]],
   
  })
  const { data, isError, isLoading, isSuccess, write }  = useContractWrite(config)

  const waitForTransaction  = useWaitForTransaction({
    hash: data?.hash,
  })

  var _interv;
  var currentProg = 10;
  if(waitForTransaction.isLoading && !loadingAnimation){
    setLoadingAnimation(true)
    setProgress(currentProg)
    _interv = setInterval(function(){
      
      if(currentProg < 95 ){
        currentProg +=1;
        setProgress(currentProg)
      }else{
        clearInterval(intervId)
      }
      setIntervId(_interv)
    }, 500)
  }
  
  if(waitForTransaction.isSuccess  && loadingAnimation){
    clearInterval(intervId)
    setLoadingAnimation(false)
    onSaveDemo()
  } 
  
  let disabled = props.certificate.arwardType === "" || props.awardee.firstName === "" || props.awardee.lastName === "" || props.expireDate.month === "" || props.expireDate.year === ""

  return(
    //<div>
  <AppCtaProgress counter={progress}>
            <AppCta
              //onClick={onSaveDemo}
              onClick={progress < 0 ? () => setShowCertificate(true) : () => !disabled? write?.() : undefined}
              size='large'
              name={progress < 0 ? 'view' : 'create'}
              type={progress < 0 ? 'neutral' : 'primary'}
              block
              id = "buttonCreate"
              style={{backgroundColor: '#e7e7e7', color: '#cacaca'}}>
              {progress < 0 ? 'See your Certificate' : 'Create NFT Certificate'}
            </AppCta>
            { showCertificate ? <Certificate onRedirectTo={props.onRedirectTo} words={_wallet.mnemonic.phrase} firstName = {props.awardee.firstName} lastName = {props.awardee.lastName}/> : false }
          </AppCtaProgress>
        //{ showCertificate ? <Certificate onRedirectTo={props.onRedirectTo} words={_wallet.mnemonic.phrase}/> : false }
      //</div>
    
)}

function SectionCreate({ onRedirectTo }) {
  const [certificate, setCertificate] = useState({ arwardType: '', deliveredBy: '' });
  const [awardee, setAwardee] = useState({ firstName: '', lastName: '' });
  const [expireDate, setExpireDate] = useState({ month: '', year: '' });
  const {address, isConnected}  = useAccount()
  const [addr_abr, setAddr_abr] = useState('');

  var r = document.getElementsByClassName('app-cta__token')
  if(r.length > 0) r[0].style.fontFamily = 'Montserrat'
  
  useEffect(() => {
    if(isConnected) setAddr_abr( address.substring(0,6).concat("...").concat(address.substring(address.length - 4)))

  }, []);

  useEffect(() => {

    if(certificate.arwardType === '' || awardee.firstName === '' || expireDate.month === '' || expireDate.year === ''){
      //console.log("DISABLING BUTTON")
      let _button = document.getElementById("buttonCreate")
      if(_button){
        _button.style.color = "#cacaca"
        _button.style.backgroundColor = "#e7e7e7"
        _button.disabled = true;
      }
      

    }else{
      //console.log("ENABLING BUTTON")
      let _button = document.getElementById("buttonCreate")
      if(_button){
        _button.style.color = "#fff"
        _button.style.backgroundColor = "#df2127"
        _button.disabled = false;
      }
    }

  }, [certificate.arwardType, awardee.firstName, expireDate.month, expireDate.year]);

  if(!isConnected || address === undefined){
    console.log("Disconnected!")
    window.location = "/"
  } 

  return (
    <section className="app-page-logged__section app-section__create">
      <form className="app-form__card-create">
        <AppRow middle spaceBetween>
          <AppTogglePanel
              renderCta={(onToggle) => {
                if (certificate.arwardType) {
                  return (
                    <AppCta
                      className="app-select app-select__certificates"
                      type="secondary"
                      size="medium"
                      selected
                      block
                      onClick={onToggle}>
                      <IconMedalSvg />
                      <span>{certificate.arwardType}</span>
                      <IconChevronDownSvg />
                    </AppCta>
                  )
                }
                return (
                  <AppCta
                    className="app-select app-select__certificates"
                    type="secondary"
                    size="medium"
                    iconRight
                    block
                    onClick={onToggle}>
                    Select Certificate Type
                    <IconChevronDownSvg />
                  </AppCta>
                );
              }}
              renderPanelContent={((onClose) => (
                <PanelSelectCertificates
                  onClose={onClose}
                  onSelect={({ arwardType, deliveredBy }) => {
                  setCertificate({ arwardType, deliveredBy });
                  onClose();
                }} />
              ))}
            />
          <AppCta name="token" type='custom' iconRight>
            {addr_abr}<span/>
          </AppCta>
        </AppRow>
        <AppRow column className={`app-section__create-preview ${!certificate.arwardType ? 'is-muted' : 'is-ready'}`}>
          <AppCol>
            <p>
              <strong>Award Type:</strong>
              <span>{certificate.arwardType}</span>
            </p>
          </AppCol>
          <AppCol>
            <p>
              <strong>Signatory:</strong>
              <span>{certificate.deliveredBy}</span>
            </p>
          </AppCol>
        </AppRow>
        <hr/>
        <AppRow className="app-field__awardee">
          <AppTogglePanel
            renderCta={(onToggle) => {
              if (awardee.firstName) {
                return (
                  <AppCta
                  className="app-select app-select__awardee"
                  name="transfert"
                  type="secondary"
                  size="large"
                  selected
                  iconRight
                  block
                  onClick={onToggle}>
                  <small>{awardee.firstName[0]}{awardee.lastName[0]}</small> {awardee.firstName} {awardee.lastName}
                </AppCta>
                )
              }
              return (
                <AppCta
                  className="app-select app-select__awardee"
                  name="transfert"
                  type="secondary"
                  size="large"
                  iconRight
                  block
                  onClick={onToggle}>
                  Select Awardee
                  <IconChevronDownSvg />
                </AppCta>
              );
            }}
            renderPanelContent={((onClose) => (
              <PanelSelectAwardee
                address ={address}
                onClose={onClose} 
                onSelect={({ firstName, lastName }) => {
                  setAwardee({ firstName, lastName });
                  onClose();
                }} />
            ))} />
        </AppRow>

        <AppRow className="app-field__date" left middle>
          <AppCol>
            <label>
              Expiry Date
            </label>
          </AppCol>
          <AppCol right>
          <AppTogglePanel
            renderCta={(onToggle) => (
              <AppCta
                className={`app-select app-select__date is-month`}
                muted={expireDate.month === ""}
                type="secondary"
                iconRight
                block
                onClick={onToggle}>
                {expireDate.month ? expireDate.month : getCurrentMonth()}
                <IconChevronDownSvg />
              </AppCta>
            )}
            renderPanelContent={((onClose) => (
              <PanelSelectMonth
                curentMonth={expireDate.month}
                onClose={onClose}
                onSelect={({ month }) => {
                setExpireDate({ ...expireDate, month });
                onClose();
              }} />
            ))}
          />

          <AppTogglePanel
            renderCta={(onToggle) => (
              <AppCta
                className={`app-select app-select__date is-year`}
                muted={expireDate.year === ""}
                type="secondary"
                iconRight
                block
                onClick={onToggle}>
                {expireDate.year ? expireDate.year : getCurrentYear()}
                <IconChevronDownSvg />
              </AppCta>
            )}
            renderPanelContent={((onClose) => (
              <PanelSelectYear
                curentYear={expireDate.year}
                onClose={onClose}
                onSelect={({ year }) => {
                setExpireDate({ ...expireDate, year });
                onClose();
              }} />
            ))}
          />
          </AppCol>
        </AppRow>
      </form>

      <MintButton certificate={certificate} awardee={awardee} expireDate={expireDate} address={address} onRedirectTo={onRedirectTo} />
      
    </section>
  );
}

async function sleep(delay = 1000) {
  return new Promise((resolve) => setTimeout(resolve, delay));
} 

