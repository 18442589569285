export default AppCta;

const TYPE_PRIMARY = 'primary';
const TYPE_SECONDARY = 'secondary';
const TYPE_NEUTRAL = 'neutral';
const TYPE_CUSTOM = 'custom';

const SIZE_MEDIUM = 'medium';
const SIZE_NORMAL = 'normal';
const SIZE_LARGE = 'large';

function AppCta(props) {
  const { disabled = false, children, onClick, id } = props;
  return (
    <div
      id={id}
      type="button"
      disabled={!!disabled}
      className={_getClassNames(props)}
      onClick={onClick}>
      {children}
    </div>
  );
}

function _getClassNames({ type, size, name, active, muted, selected, block, iconLeft, iconRight, className }) {
  let value = 'app-cta';
  value += _getCtaType(type);
  value += _getCtaSize(size);
  value += typeof name === 'string' && name !== '' ? ` app-cta__${name}` : '';
  value += !!active ? ' is-actived' : '';
  value += !!selected ? ' is-selected' : '';
  value += !!muted ? ' is-muted' : '';
  value += !!block ? ' is-block' : '';
  value += !!iconLeft ? ' has-icon-left' : '';
  value += !!iconRight ? ' has-icon-right' : '';
  value += typeof className === 'string' && className !== '' ? ` ${className}` : '';

  return value;
}

function _getCtaType(type) {
  let value = TYPE_PRIMARY;
  if ([TYPE_SECONDARY, TYPE_CUSTOM, TYPE_NEUTRAL].includes(type)) {
    value = type;
  }

  return ` app-cta--${value}`;
}

function _getCtaSize(size) {
  let value = SIZE_NORMAL;
  if ([SIZE_LARGE, SIZE_MEDIUM].includes(size)) {
    value = size;
  }

  return ` app-cta--size-${value}`;
}
