import { useState } from 'react';
import { AppRow } from '../../../components/app-grid';
import { AppCta } from '../../../components/app-cta';

import {useContractWrite, usePrepareContractWrite , useWaitForTransaction} from 'wagmi';
import {COLLECTION_ADDRESS, COLLECTION_ABI} from '../../../contracts/Contracts';
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';

const RevokeButton = (props) => {
  
  const [finish, setFinish] = useState(false);
  const [intervalId, setIntervalId] = useState(-1);

  const addRecentTransaction = useAddRecentTransaction();
  
  const { config }  = usePrepareContractWrite({
    address: COLLECTION_ADDRESS,
    abi: COLLECTION_ABI,
    functionName: '_removeCertificate',
    args: [props.certificateId],
   
  })
  const { data, isError, isLoading, isSuccess, write }  = useContractWrite(config)
  
  const waitForTransaction  = useWaitForTransaction({
    hash: data?.hash,
  })

  if(waitForTransaction.isLoading && intervalId === -1){
    let dots = ""
    setIntervalId(setInterval(function(){
      if(dots === "..."){
        dots = ""
      }else{
        dots += "."
      }

      document.getElementById("revokeButton"+props.certificateId).innerText = dots+"Loading"+dots
    }, 400)
    )
    
  }
  
  
  if(waitForTransaction.isSuccess && !finish){
    clearInterval(intervalId)
    setFinish(true)
    setIntervalId(-1)
    {props.onClose()}
  } 
  
  return(
    <AppCta
      onClick={!waitForTransaction.isLoading && !waitForTransaction.isSuccess ? () => write?.() : undefined}
      id = {"revokeButton"+props.certificateId}
      size='large'
      block>
      {waitForTransaction.isLoading? "Loading..." : "Confirm"}
    </AppCta>
)}


export function PanelRevokeCertificate({ onClose, certificateId }) {
  
  return (
    <form className="app-form__card-revoke">
      <AppRow className="app-field--text" center middle>
        <p>
          Please Confirm
          <br />
          To Revoke your Certificate
        </p>
      </AppRow>

      <RevokeButton onClose={onClose} certificateId={certificateId}/>
    </form>
  );
}
