import { ReactComponent as BrandSvg } from '../app-svg/moneygram-full-inline.svg';
import { ReactComponent as IconMedalSvg } from '../app-svg/icon-medal.svg';
import { ReactComponent as IconNftSvg } from '../app-svg/icon-nft.svg';

import { AppRow, AppCol } from '../app-grid';

export default AppCardCertificate;

function AppCardCertificate({ children, hide, id, type, firstName, lastName, timeExpiry, timeMint }) {
  const dateStr = '20' + timeExpiry.split("/")[1] + '-' + timeExpiry.split("/")[0] + '-01';
  const date = new Date(dateStr);
  var expired = false;
  if(date.getTime() < new Date(Date.now())){
    expired = true;
  }
  return (
    <div key={id} id={"appCard"+id} className={`app-card__certificate ${!!hide ? 'hidden' : ''}`}>
      <AppRow top left>
        {children}
        <AppCol className="app-card__certificate__logo-brand" middle right>
          <BrandSvg />  
        </AppCol>
      </AppRow>
      <AppRow className="app-card__certificate__details" left>
        <IconMedalSvg />
        <p>
          <strong>
            Certificate
          </strong>
          <span>
            {type}
          </span>
        </p>
      </AppRow>
      <AppRow className="app-card__certificate__dates" left>
        <p>
          <small>
            <span>MINTING</span>
            <span>DATE</span>
          </small>
          <strong>
            {timeMint}
          </strong>
        </p>
        <p>
          <small>
            <span>VALIDITY</span>
            <span>DATE</span>
          </small>
          <strong id={'expiryDate'+id}>
            {expired? "Expired" : timeExpiry}
          </strong>
        </p>
      </AppRow>
      <AppRow className="app-card__certificate__owner" middle left>
        <span>{firstName? firstName[0]: 'A'}{lastName? lastName[0] : 'A'}</span>
        <p>
          <strong id={'fullName'+id}>
            {expired? firstName+" "+lastName : firstName+" "+lastName} 
          </strong>
        </p>
        <AppCol className="app-card__certificate__logo-crypto" middle right>
          <IconNftSvg />  
        </AppCol>
      </AppRow>
    </div>
  );
}
