import { ReactComponent as IconMedalSvg } from '../../../components/app-svg/icon-medal.svg';

import { AppRow } from '../../../components/app-grid';
import { AppCta } from '../../../components/app-cta';

export function PanelSelectCertificates({ onSelect }) {
  return (
    <div className="app-panel__select__certificates">
      <AppRow spaceBetween>
        <label>
          Certificate Type
        </label>
        <AppCta
          name="add"
          type="custom"
          size="medium"
          iconLeft
          disabled>
          + Add
        </AppCta>
      </AppRow>
      <AppRow center>
        <ul>
          <li>
            <button
              type="button"
              //className="is-actived"
              onClick={() => onSelect({
                arwardType: 'Agents Compliance Training',
                deliveredBy: 'Ali Seghir - Head of Compliance'
              })}>
              <IconMedalSvg />
              <span>
              Agents Compliance Training by MoneyGram
              </span>
            </button>
          </li>
          <li>
            <button type="button"
              onClick={() => onSelect({
                arwardType: 'Compliance and Anti-Fraud Measures',
                deliveredBy: 'Ali Seghir - Head of Compliance'
              })}>
              <IconMedalSvg />
              <span>
                  Compliance and Anti-Fraud Measures
              </span>
            </button>
          </li>
          <li>
            <button type="button"
              onClick={() => onSelect({
                arwardType: 'AML/CFT Compliance 3',
                deliveredBy: 'ALI SEGUIR. Regional Compliance Manager'
              })}>
              <IconMedalSvg />
              <span>
                AML/CFT Compliance 3
              </span>
            </button>
          </li>
          <li>
            <button type="button"
              onClick={() => onSelect({
                arwardType: 'AML/CFT Compliance 4',
                deliveredBy: 'ALI SEGUIR. Regional Compliance Manager'
              })}>
              <IconMedalSvg />
              <span>
                AML/CFT Compliance 4
              </span>
            </button>
          </li>
        </ul>
      </AppRow>
    </div>
  );
}
