function AppGrid(props) {
  return !!props?.debug
    ? <div className={_getClassNames(props)}><div className="dv">{props.children}</div></div>
    : <div className={_getClassNames(props)}>{props.children}</div>
  ;
};

function _getClassNames({ name, column, top, bottom, center, middle, left, right, spaceBetween, className }) {
  let value = 'app-grid';
  value += typeof name === 'string' && name !== '' ? ` app-grid__${name}` : '';
  value += !!column ? ' app-grid--column' : '';
  value += !!top ? ' app-grid--top' : '';
  value += !!bottom ? ' app-grid--bottom' : '';
  value += !!center ? ' app-grid--center' : '';
  value += !!middle ? ' app-grid--middle' : '';
  value += !!left ? ' app-grid--left' : '';
  value += !!right ? ' app-grid--right' : '';
  value += !!spaceBetween ? ' app-grid--space-between' : '';
  value += typeof className === 'string' && className !== '' ? ` ${className}` : '';

  return value;
}

export default AppGrid;
