import { useState } from 'react';

export default AppTogglePanel;

function AppTogglePanel({ onChange, renderCta, renderPanelContent, left }) {
  const [active, setActive] = useState(false);

  const onToggle = () => {
    if (onChange) {
      onChange({ isActive: !active });
    }
    setActive(val => !val);
  }

  return (
    <div className='app-toggle-panel'>
      <div className='app-toggle-panel__cta'>
        { renderCta(onToggle) }
      </div>
      <div style = {{left: `${left}`}}
        className={`app-toggle-panel__dialog fade ${active ? 'show' : '' }`}>
        <div className='app-toggle-panel__content' >
          { renderPanelContent(onToggle) }
        </div>
      </div>
    </div>
  );
}
