import { useState } from 'react';

import { ReactComponent as IconWarningSvg } from '../../../components/app-svg/icon-warning.svg';
//import { ReactComponent as MoneygramCertificateSvg } from '../../../components/app-svg/moneygram-certificate.svg';
//import { ReactComponent as MoneygramCertificateSvg2 } from '../../../components/app-svg/moneygram-certificate2.svg';
//import { ReactComponent as MoneygramCertificateSvg3 } from '../../../components/app-svg/moneygram-certificate3.svg';
//import { ReactComponent as MoneygramCertificateSvg4 } from '../../../components/app-svg/moneygram-certificate4.svg';
//import MohamedImage from '../../../components/app-svg/2.jpg';
//import TahaImage from '../../../components/app-svg/3.jpg';
//import MayaImage from '../../../components/app-svg/4.jpg';
//import SimonImage from '../../../components/app-svg/5.jpg';
//import ZaynImage from '../../../components/app-svg/1.jpg';
import NeelehsImage from '../../../components/app-svg/neeleshgopalan020223.jpg'
import PaulImage from '../../../components/app-svg/paulsunny020223.jpg'
import ReshamImage from '../../../components/app-svg/reshampaul020223.jpg'
import AratiImage from '../../../components/app-svg/aratiparui020223.jpg'
import HenryImage from '../../../components/app-svg/henrychen020223.jpg'

import HamadImage from '../../../components/app-svg/hamadalwashmi190323.jpg'
import NaifalImage from '../../../components/app-svg/naifaldahmasi190323.jpg'
import AbdulImage from '../../../components/app-svg/abudllahalfaraj190323.jpg'
import TurkialImage from '../../../components/app-svg/turkialtuais190323.jpg'
import AnasalImage from '../../../components/app-svg/anasalamoud190323.jpg'
import OmarhuImage from '../../../components/app-svg/omarhumaysan190323.jpg'
import OmaralImage from '../../../components/app-svg/omaralhumaidan190323.jpg'
import SalehalImage from '../../../components/app-svg/salehalsalamah190323.jpg'
import RaadgashImage from '../../../components/app-svg/raadgashgary190323.jpg'
import OmarabImage from '../../../components/app-svg/omarabumouti190323.jpg'
import FahadImage from '../../../components/app-svg/fahadfrasani190323.jpg'
import KhalidImage from '../../../components/app-svg/khalidalshabanat190323.jpg'
import AbdullahImage from '../../../components/app-svg/abdullahalosaimi190323.jpg'
import AhmadalImage from '../../../components/app-svg/ahmadalbaraikan190323.jpg'

import { ReactComponent as IconCloseSvg } from '../../../components/app-svg/icon-close.svg';

import { AppRow } from '../../../components/app-grid';
import { AppCta } from '../../../components/app-cta';

import { TAB_CERTIFICATES } from '../../../constants';

export function Certificate({ onRedirectTo, words, firstName, lastName }) {
  const [showCertificate, setShowCertificate] = useState(false);

  function copyWords(){
    navigator.clipboard.writeText(words);
    document.getElementsByClassName('app-certificate__copy')[0].style.cursor = "progress"
    setTimeout( function(){
      document.getElementsByClassName('app-certificate__copy')[0].style.cursor = "pointer"
    }, 300)
  }

  if (showCertificate) {
    return (
      <div className="app-certificate is-step-2">
        <div className="app-certificate__content">
          <span className="app-certificate__document">
            
            {firstName === 'Neelesh' ? <img style={{width: '100%'}} src={NeelehsImage}/> :
            firstName === 'Paul' ? <img style={{width: '100%'}} src={PaulImage}/> :
            firstName === 'Henry' ? <img style={{width: '100%'}} src={HenryImage}/> :
            firstName === 'Arati' ? <img style={{width: '100%'}} src={AratiImage}/> :

            firstName === 'Hamad' ? <img style={{width: '100%'}} src={HamadImage}/> :
            firstName === 'Naif' ? <img style={{width: '100%'}} src={NaifalImage}/> :
            firstName === 'Abdullah' && lastName === 'Al-Faraj' ? <img style={{width: '100%'}} src={AbdulImage}/> :
            firstName === 'Turki' ? <img style={{width: '100%'}} src={TurkialImage}/> :
            firstName === 'Anas' ? <img style={{width: '100%'}} src={AnasalImage}/> :
            firstName === 'Omar' && lastName === 'M. Humaysan' ? <img style={{width: '100%'}} src={OmarhuImage}/> :
            firstName === 'Omar' && lastName === 'A. Alhumaidan' ? <img style={{width: '100%'}} src={OmaralImage}/> :
            firstName === 'Saleh' ? <img style={{width: '100%'}} src={SalehalImage}/> :
            firstName === 'Raad' ? <img style={{width: '100%'}} src={RaadgashImage}/> :
            firstName === 'Omar' && lastName === 'Abu Mouti' ? <img style={{width: '100%'}} src={OmarabImage}/> :
            firstName === 'Fahad' ? <img style={{width: '100%'}} src={FahadImage}/> :
            firstName === 'Khalid' ? <img style={{width: '100%'}} src={KhalidImage}/> :
            firstName === 'Abdullah' && lastName === 'Al-Osaimi' ? <img style={{width: '100%'}} src={AbdullahImage}/> :
            firstName === 'Ahmad' ? <img style={{width: '100%'}} src={AhmadalImage}/> :
            
            <img style={{width: '100%'}} src={ReshamImage}/>}

          </span>
          <AppCta
            onClick={() => onRedirectTo(TAB_CERTIFICATES)}
            name='close'
            size='normal'
            type='secondary'
            iconLeft>
            <IconCloseSvg />
            Close & View Certificates list
          </AppCta>
        </div>
      </div>
    )
  }

  return (
    <div className="app-certificate is-step-1">
      <div className="app-certificate__content">
        <p className='app-certificate__notification'>
          <IconWarningSvg />
          <span>
            Write down or copy these words in the right order and save them somewhere safe.
          </span>
        </p>

        <AppRow
          center
          className="app-certificate__words">
          {words.split(" ").map(word => (
            <AppCta
              key={word}
              type="secondary"
              size="medium">
              <span>{word}</span>
            </AppCta>
          ))}
        </AppRow>

        <p className='app-certificate__copy' onClick={() => copyWords()} style={{cursor:'pointer'}}>
          COPY
        </p>

        <AppCta
          onClick={() => setShowCertificate(true)}
          name='save'
          size='large'
          type='secondary'>
          Continue
        </AppCta>

        <p className='app-certificate__legend'>
          <span>
            Never share recovery phrase with anyone, store it securely
          </span>
        </p>
      </div>
    </div>
  );
}