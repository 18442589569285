import { useState, useEffect } from 'react';

import { AppRow, AppCol } from '../../../components/app-grid';
import { AppCta } from '../../../components/app-cta';

import {useAccount, useContractWrite, usePrepareContractWrite , useWaitForTransaction} from 'wagmi';
import {COLLECTION_ADDRESS, COLLECTION_ABI} from '../../../contracts/Contracts';
import { connectorsForWallets, useAddRecentTransaction } from '@rainbow-me/rainbowkit';

const TransferButton = (props) => {
  const [finish, setFinish] = useState(false);
  const {address}  = useAccount()
  const [intervalId, setIntervalId] = useState();

  const addRecentTransaction = useAddRecentTransaction();
  const { config }  = usePrepareContractWrite({
    address: COLLECTION_ADDRESS,
    abi: COLLECTION_ABI,
    functionName: 'transferFrom',
    args: [address.toString(), props.recipient.toString(), props.certificateID],
   
  })
  const { data, isError, isLoading, isSuccess, write }  = useContractWrite(config)

  const waitForTransaction  = useWaitForTransaction({
    hash: data?.hash,
  })
  
  if(waitForTransaction.isLoading && intervalId === undefined){
    let dots = ""
    setIntervalId(setInterval(function(){
      if(dots === "..."){
        dots = ""
      }else{
        dots += "."
      }

      document.getElementById("buttonTransfer"+props.certificateID).innerText = dots+"Loading"+dots
    }, 400)
    )
    
  }


  if(waitForTransaction.isSuccess && !finish){
    document.getElementById("appCard"+props.certificateID).style.display = "none"
    clearInterval(intervalId)
    setFinish(true)
    {props.onClose()}
  } 
  
  return(<AppCta
    //onClick={() => onClose()}
    onClick={!waitForTransaction.isLoading && !waitForTransaction.isSuccess? () => write?.() : undefined }
    //disabled={address === '' || props.recipient === '' || props.certificateID === 0}
    id = {"buttonTransfer"+props.certificateID}
    size='large'
    block>
    {waitForTransaction.isLoading? "Loading" : "Transfer Certificate"}
  </AppCta>
)}
 
export function FormCardTransfert({ onClose, certificateID }) {
  const [ETHAddress, setETHAddress] = useState("");

  useEffect(() => {

    if(ETHAddress === ""){
      //console.log("DISABLING BUTTON")
      let _button = document.getElementById("buttonTransfer"+certificateID)
      if(_button){
        _button.style.color = "#cacaca"
        _button.style.backgroundColor = "#e7e7e7"
        _button.disabled = true;
      }
      

    }else{
      //console.log("ENABLING BUTTON")
      let _button = document.getElementById("buttonTransfer"+certificateID)
      if(_button){
        _button.style.color = "#fff"
        _button.style.backgroundColor = "#df2127"
        _button.disabled = false;
      }
    }

  }, [ETHAddress]);

  return (
    <form className="app-form__card-transfert">
      <AppRow className="app-field--text" left middle>
        <AppCol>
          <label>
            ERC-20 address
          </label>
        </AppCol>
        <AppCol>
          <input
            type="text"
            placeholder="0x11df33..."
            value={ETHAddress}
            onChange={(e) => {
              setETHAddress(e.target.value.toString())}
            }
              />
        </AppCol>
      </AppRow>

      <TransferButton recipient={ETHAddress} certificateID={parseInt(certificateID)} onClose={onClose}/>
    </form>
  );
}
