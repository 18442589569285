import { ReactComponent as LogoSvg } from '../app-svg/moneygram-logo.svg';
import { ReactComponent as BrandSvg } from '../app-svg/moneygram-brand.svg';

export default AppLayout;

function AppLayout({ name, children }) {
  return (
    <main className={`app-layout app-layout__${name}`}>
      <header className="app-header">
        <div className="app-header__col is-right">
          <LogoSvg />
        </div>
        <div className="app-header__col is-left">
          <BrandSvg />
          <h1>
            <span className="sr-only">MoneyGram</span>
            <span>NFT Certificates</span>
            Platform
          </h1>
        </div>
      </header>
      <div className={`app-body`}>
        <div className={`app-page app-page-${name}`}>
          {children}
        </div>
      </div>
      <footer className="app-footer">
        <p>
          Copyright 2022-2023 © MoneyGram, All rights reserved. Developed by <a style={{color:'#DF2127'}} href='https://parallelgroup.ae' target="_blank">Parallel</a>
        </p>
      </footer>
    </main>
  );
}
