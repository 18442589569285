import { useState } from 'react';
import { ReactComponent as IconChevronDownSvg } from '../../../components/app-svg/icon-chevron-down.svg';

import { AppRow } from '../../../components/app-grid';
import { AppTogglePanel } from '../../../components/app-toggle-panel';
import { AppCta } from '../../../components/app-cta';
import { AppCardCertificate } from '../../../components/app-card';
import { PanelEditCertificate } from './PanelEditCertificate';
import { PanelRevokeCertificate } from './PanelRevokeCertificate';
import { PanelViewCertificate } from './PanelViewCertificate';

import { useEffect } from 'react';
import {useAccount, useContractRead, useContractInfiniteReads , paginatedIndexesConfig} from 'wagmi';
import {COLLECTION_ADDRESS, COLLECTION_ABI} from '../../../contracts/Contracts';

import {ethers} from 'ethers'

const TAB_EDIT = 'edit';
const TAB_REVOKE = 'revoke';
const TAB_VIEW = 'view';

const tabConfigList = [
  [TAB_EDIT, 'Edit', PanelEditCertificate],
  [TAB_REVOKE, 'Revoke', PanelRevokeCertificate],
  [TAB_VIEW, 'View', PanelViewCertificate]
];
//let myCertificates2 = []

export default SectionCertificates;


function SectionCertificates(props) {
  const [activeTab, setActiveTab] = useState(TAB_EDIT);
  const [curentCard, selectCurrentCard] = useState('');
  const {isConnected, address}  = useAccount()
  const [myCertificates, setMyCertificates] = useState([]);
  const [load, setLoad] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {  
      var r = document.getElementsByClassName('app-cta')
      for(let i=0;i<r.length; i++){
        if(!r[i].className.includes("app-cta__create")){
          r[i].style.pointerEvents = 'auto';
        }
      }

  }, []);

  
  
  

  if(!isConnected || address === undefined){
    console.log("Disconnected!")
    window.location = "/"
  } 

  //let myCertificates2 = []

  const details  = useContractInfiniteReads({cacheKey:"certData",
    ...paginatedIndexesConfig(
        (index2) => ({address: COLLECTION_ADDRESS, abi: COLLECTION_ABI, functionName: 'getCertificateDetails', args: [index2],
        }),
        { start: 1, perPage: parseInt(props.numCertificates), direction: 'increment' },
    ),
    onSuccess(data) {
      //console.log('Reading CERTIFICATES DETAILS Success', data)
    },
  })

  if((details.isSuccess && !loaded) || (details.isSuccess && details.data.pages[0].length > myCertificates.length)){
    setMyCertificates(details.data.pages[0])
    setLoaded(true)
  } 

  useEffect(() => {  
    async function fetchData(){
      console.log("Fetching data...")
      const provider = new ethers.providers.JsonRpcProvider("https://data-seed-prebsc-2-s3.binance.org:8545/" ) // provider for signing transaction
      //const provider = new ethers.providers.AlchemyProvider("goerli", 'D6SKBwgFrh2PIEcsKrOnUUKGIiR_Ewc3')
      let erc20 = new ethers.Contract(COLLECTION_ADDRESS, COLLECTION_ABI, provider);
      erc20 = erc20.connect(provider)
      let certificates = []
      for(let i = 1 ; i <= props.numCertificates; i++){
        let current = await erc20.getCertificateDetails(i)
        certificates.push(current)
      }
      //console.log(certificates)
      setMyCertificates(certificates)
      setLoad(false)
    }

    if(load){
      fetchData()
    }

}, [load]); 
    
  return (
    <section className={`app-page-logged__section app-section__certificates ${curentCard !== '' ? 'no-scroll' : '' }`}>
      <AppRow column>
      {myCertificates.length === 0 ? 
         <p style={{fontWeight:"normal", fontFamily:"Montserrat", fontSize:"14px"}}>No certificate found.</p>
        :
        myCertificates.map((elem, index) => 
        <AppCardCertificate key={index} hide={curentCard !== "" && curentCard !== 'card-id-0'} id={parseInt(index +1)} type={elem[2]} firstName={elem[3]} lastName={elem[4]}
           timeExpiry={(parseInt(new Date(elem[0] * 1000).getMonth() + 1) < 10 ? '0'.concat(parseInt(new Date(elem[0] * 1000).getMonth() + 1)) : parseInt(new Date(elem[0] * 1000).getMonth() + 1) )+
            '/' + 
            new Date(elem[0] * 1000).getFullYear().toString().substring(new Date(elem[0] * 1000).getFullYear().toString().length - 2)} 

           timeMint={(parseInt(new Date(elem[1] * 1000).getMonth() + 1) < 10 ? '0'.concat(parseInt(new Date(elem[1] * 1000).getMonth() + 1)) : parseInt(new Date(elem[1] * 1000).getMonth() + 1) )+
           '/' + 
           new Date(elem[1] * 1000).getFullYear().toString().substring(new Date(elem[1] * 1000).getFullYear().toString().length - 2)}>
        <AppTogglePanel
          onChange={({ isActive}) => { selectCurrentCard( isActive? 'card-id-0' : '') }}
          renderCta={(onClick) => (
            <AppCta
              className="app-cta__card-manage"
              name="manage"
              type="custom"
              iconRight
              onClick={onClick}>
              Options
              <IconChevronDownSvg />
            </AppCta>
          )}
          renderPanelContent={((onClose) => (
            <>
              <nav className="app-nav__secondary">
                <AppRow left spaceBetween>
                  {tabConfigList.map(([tabName, TabLabel]) => (
                    <AppCta
                      key={`tab-${tabName}`}
                      active={tabName === activeTab}
                      onClick={() => setActiveTab(tabName)}
                      type="custom">
                      {TabLabel}
                    </AppCta>
                  ))}
                  <AppCta
                    onClick={() => onClose()}
                    type="custom">
                    Close
                  </AppCta>
                </AppRow>
              </nav>
              {tabConfigList.map(([tabName,, Tab]) => tabName === activeTab ? <Tab key={`tab-1-${tabName}`} onClose={function(){onClose(); setLoad(true)}  } certificateId={parseInt(index+1)}
               type={elem[2]} firstName={elem[3]} lastName={elem[4]} 
               timeExpiry={(parseInt(new Date(elem[0] * 1000).getMonth() + 1) < 10 ? '0'.concat(parseInt(new Date(elem[0] * 1000).getMonth() + 1)) : parseInt(new Date(elem[0] * 1000).getMonth() + 1) ) +
                '/' + 
                new Date(elem[0] * 1000).getFullYear().toString().substring(new Date(elem[0] * 1000).getFullYear().toString().length - 2)}

              timeMint={parseInt(new Date(elem[1] * 1000).getMonth() + 1) < 10 ? '0'.concat(parseInt(new Date(elem[1] * 1000).getMonth() + 1)) : parseInt(new Date(elem[1] * 1000).getMonth() + 1) +
                  '/' + 
                  new Date(elem[1] * 1000).getFullYear().toString().substring(new Date(elem[1] * 1000).getFullYear().toString().length - 2)}
               />  : false)}
            </>
          ))} />
      </AppCardCertificate>
        )}
        
        
      </AppRow>
    </section>
  ); 

}
