export function getCurrentYear() {
  return new Date().getFullYear();
}

export function getCurrentMonth() {
  return new Intl.DateTimeFormat("en-US", { month: "long" }).format(new Date());
}

export function getMonths() {
  const year = new Date().getFullYear();
  let results = [];
  for (let i=0; i<12; i++) {
    results.push(new Intl.DateTimeFormat("en-US", { month: "long" }).format(new Date(year, i)));
  }
  return results;
}

export function getYears(max = 9) {
  const start = new Date().getFullYear();
  let results = [];
  for (let i=0; i<max; i++) {
    results.push(start + i);
  }
  return results;
}