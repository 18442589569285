//import { ReactComponent as MoneygramCertificateSvg } from '../../../components/app-svg/moneygram-certificate.svg';
//import { ReactComponent as MoneygramCertificateSvg2 } from '../../../components/app-svg/moneygram-certificate2.svg';
//import { ReactComponent as MoneygramCertificateSvg3 } from '../../../components/app-svg/moneygram-certificate3.svg';
//import { ReactComponent as MoneygramCertificateSvg4 } from '../../../components/app-svg/moneygram-certificate4.svg';
//import MohamedImage from '../../../components/app-svg/2.jpg';
//import TahaImage from '../../../components/app-svg/3.jpg';
//import MayaImage from '../../../components/app-svg/4.jpg';
//import SimonImage from '../../../components/app-svg/5.jpg';
//import ZaynImage from '../../../components/app-svg/1.jpg';
import NeelehsImage from '../../../components/app-svg/neeleshgopalan020223.jpg'
import PaulImage from '../../../components/app-svg/paulsunny020223.jpg'
import ReshamImage from '../../../components/app-svg/reshampaul020223.jpg'
import AratiImage from '../../../components/app-svg/aratiparui020223.jpg'
import HenryImage from '../../../components/app-svg/henrychen020223.jpg'

import HamadImage from '../../../components/app-svg/hamadalwashmi190323.jpg'
import NaifalImage from '../../../components/app-svg/naifaldahmasi190323.jpg'
import AbdulImage from '../../../components/app-svg/abudllahalfaraj190323.jpg'
import TurkialImage from '../../../components/app-svg/turkialtuais190323.jpg'
import AnasalImage from '../../../components/app-svg/anasalamoud190323.jpg'
import OmarhuImage from '../../../components/app-svg/omarhumaysan190323.jpg'
import OmaralImage from '../../../components/app-svg/omaralhumaidan190323.jpg'
import SalehalImage from '../../../components/app-svg/salehalsalamah190323.jpg'
import RaadgashImage from '../../../components/app-svg/raadgashgary190323.jpg'
import OmarabImage from '../../../components/app-svg/omarabumouti190323.jpg'
import FahadImage from '../../../components/app-svg/fahadfrasani190323.jpg'
import KhalidImage from '../../../components/app-svg/khalidalshabanat190323.jpg'
import AbdullahImage from '../../../components/app-svg/abdullahalosaimi190323.jpg'
import AhmadalImage from '../../../components/app-svg/ahmadalbaraikan190323.jpg'

export function PanelViewCertificate({ firstName, lastName }) {
  return (
    <div className="app-panel__document-viewer">
      {firstName === 'Neelesh' ? <img style={{width: '100%'}} src={NeelehsImage}/> :
       firstName === 'Paul' ? <img style={{width: '100%'}} src={PaulImage}/> :
       firstName === 'Henry' ? <img style={{width: '100%'}} src={HenryImage}/> :
       firstName === 'Arati' ? <img style={{width: '100%'}} src={AratiImage}/> :

       firstName === 'Hamad' ? <img style={{width: '100%'}} src={HamadImage}/> :
       firstName === 'Naif' ? <img style={{width: '100%'}} src={NaifalImage}/> :
       firstName === 'Abdullah' && lastName === 'Al-Faraj' ? <img style={{width: '100%'}} src={AbdulImage}/> :
       firstName === 'Turki' ? <img style={{width: '100%'}} src={TurkialImage}/> :
       firstName === 'Anas' ? <img style={{width: '100%'}} src={AnasalImage}/> :
       firstName === 'Omar' && lastName === 'M. Humaysan' ? <img style={{width: '100%'}} src={OmarhuImage}/> :
       firstName === 'Omar' && lastName === 'A. Alhumaidan' ? <img style={{width: '100%'}} src={OmaralImage}/> :
       firstName === 'Saleh' ? <img style={{width: '100%'}} src={SalehalImage}/> :
       firstName === 'Raad' ? <img style={{width: '100%'}} src={RaadgashImage}/> :
       firstName === 'Omar' && lastName === 'Abu Mouti' ? <img style={{width: '100%'}} src={OmarabImage}/> :
       firstName === 'Fahad' ? <img style={{width: '100%'}} src={FahadImage}/> :
       firstName === 'Khalid' ? <img style={{width: '100%'}} src={KhalidImage}/> :
       firstName === 'Abdullah' && lastName === 'Al-Osaimi' ? <img style={{width: '100%'}} src={AbdullahImage}/> :
       firstName === 'Ahmad' ? <img style={{width: '100%'}} src={AhmadalImage}/> :
          
       <img style={{width: '100%'}} src={ReshamImage}/>}
    </div>
  );
}
