import { useState, useEffect } from 'react';

import { ReactComponent as IconChevronRightSvg } from '../../../components/app-svg/icon-chevron-right.svg';

import { AppRow, AppCol } from '../../../components/app-grid';
import { AppCta } from '../../../components/app-cta';

import {useContractRead, useContractWrite, usePrepareContractWrite , useWaitForTransaction} from 'wagmi';
import {COLLECTION_ADDRESS, COLLECTION_ABI} from '../../../contracts/Contracts';
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';

const TAB_SELECT = "select";
const TAB_ADD = "add";

export function PanelSelectAwardee({ onClose, onSelect }) {
  const [activeTab, SetTab] = useState(TAB_SELECT);
  const onAdd = () => SetTab(TAB_ADD);

  const awardees  = useContractRead({
    address: COLLECTION_ADDRESS,
    abi: COLLECTION_ABI,
    functionName: 'getAwardees',
    args: [],
    suspense: true,
    cacheTime: 1000,
    watch: true,
    onSuccess(data) {
      
      //console.log('Awardees: ', data)
    },
  })

  if(activeTab === TAB_ADD) {
    return (<PanelAddAwardee onBack={() => SetTab(TAB_SELECT)} onSave={() => onClose()}/>);
  }

  let _awardees = [
    { firstName: 'Neelesh', lastName: 'Gopalan' },
    { firstName: 'Paul', lastName: 'Sunny' },
    { firstName: 'Henry', lastName: 'Chen' },
    { firstName: 'Arati', lastName: 'Parui' },
    { firstName: 'Resham', lastName: 'Paul' },
    { firstName: 'Hamad', lastName: 'I. Alwashmi' },
    { firstName: 'Naif', lastName: 'Al Dahmashi' },
    { firstName: 'Abdullah', lastName: 'Al-Faraj' },
    { firstName: 'Turki', lastName: 'I. Al-Tuais' },
    { firstName: 'Anas', lastName: 'S. Alamoud' },
    { firstName: 'Omar', lastName: 'M. Humaysan' },
    { firstName: 'Omar', lastName: 'A. Alhumaidan' },
    { firstName: 'Saleh', lastName: 'Al-Salamah' },
    { firstName: 'Raad', lastName: 'Gashgary' },
    { firstName: 'Omar', lastName: 'Abu Mouti' },
    { firstName: 'Fahad', lastName: 'Frasani' },
    { firstName: 'Khalid', lastName: 'Al-Shabanat' },
    { firstName: 'Abdullah', lastName: 'Al-Osaimi' },
    { firstName: 'Ahmad', lastName: 'Albaraikan' }
  ]
  //console.log(_awardees)

  if(awardees.isSuccess){
    
    
    return (
      <div className="app-panel__select__awardee">
        <AppRow spaceBetween>
          <label>
            Awardee
          </label>
          <AppCta
            onClick={() => onAdd()}
            name="add"
            type="custom"
            size="medium"
            iconLeft>
            + Add
          </AppCta>
        </AppRow>
        <AppRow center>
          <ul>
          {awardees['data'].map((index) => 
              <li key={index}>
              <button
                onClick={() => onSelect({ firstName: index.firstName, lastName: index.lastName })}
                type="button" 
                //className="is-actived"
                >
                <small>{index.firstName[0]}{index.lastName[0]}</small>
                <span>
                  {index.firstName} {index.lastName}
                </span>
              </button>
            </li>
          )}
          {_awardees.map((index) => 
              <li key={index.firstName + index.lastName}>
              <button
                onClick={() => onSelect({ firstName: index.firstName, lastName: index.lastName })}
                type="button" 
                //className="is-actived"
                >
                <small>{index.firstName[0]}{index.lastName[0]}</small>
                <span>
                  {index.firstName} {index.lastName}
                </span>
              </button>
            </li>
          )}
          </ul>
        </AppRow>
      </div>
    );
  }
}

const AddAwardeeButton = (props) => {
  const [finish, setFinish] = useState(false);
  const [intervalId, setIntervalId] = useState(-1);

  const addRecentTransaction = useAddRecentTransaction();
  
  const { config }  = usePrepareContractWrite({
    address: COLLECTION_ADDRESS,
    abi: COLLECTION_ABI,
    functionName: 'addAwardee',
    args: [props.firstName, props.lastName],
   
  })
  const { data, isError, isLoading, isSuccess, write }  = useContractWrite(config)
  

  const waitForTransaction  = useWaitForTransaction({
    hash: data?.hash,
  })
  
  
  if(waitForTransaction.isLoading && intervalId === -1){
    let dots = ""
    setIntervalId(setInterval(function(){
      if(dots === "..."){
        dots = ""
      }else{
        dots += "."
      }

      document.getElementsByClassName('app-panel__add__awardee')[0].getElementsByClassName('app-cta--size-large')[0].innerText = dots+"Loading"+dots
    }, 400)
    )
    
  }

  if(waitForTransaction.isSuccess && !finish ){
    clearInterval(intervalId)
    setFinish(true)
    setIntervalId(-1)
    {props.onBack()}
  } 

  let disabled = props.firstName === '' || props.lastName === ''
  return(
    <AppCta
          onClick={!waitForTransaction.isLoading && !disabled? () => write?.() : !disabled? () => props.onBack() : undefined}
          id = "buttonAddAwardee"
          size='large'
          block>
          {waitForTransaction.isLoading ? "Loading" : "Confirm"}
    </AppCta>
)}

function PanelAddAwardee({ onBack, onSave }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {

    if(firstName === '' || lastName === ''){
      //console.log("DISABLING BUTTON")
      let _button = document.getElementById("buttonAddAwardee")
      if(_button){
        _button.style.color = "#cacaca"
        _button.style.backgroundColor = "#e7e7e7"
        _button.disabled = true;
      }

    }else{
      //console.log("ENABLING BUTTON")
      let _button = document.getElementById("buttonAddAwardee")
      if(_button){
        _button.style.color = "#fff"
        _button.style.backgroundColor = "#df2127"
        _button.disabled = false;
      }
    }

  }, [firstName, lastName]);

  return (
    <div className="app-panel__add__awardee">
      <AppRow spaceBetween>
        <AppCta
          onClick={() => onBack()}
          name="back"
          type="custom"
          size="medium"
          iconRight>
          <IconChevronRightSvg />
          Back
        </AppCta>
        <label>
          Add Awardee
        </label>
      </AppRow>
      <form className="app-form__awardee-add">
        <AppRow className="app-field--text" left middle>
          <AppCol>
            <label>
              First Name
            </label>
          </AppCol>
          <AppCol>
            <input
              type="text"
              value={firstName}
              onChange={e => setFirstName(e.target.value)} />
          </AppCol>
        </AppRow>

        <AppRow className="app-field--text" left middle>
          <AppCol>
            <label>
              Last Name
            </label>
          </AppCol>
          <AppCol>
            <input
              type="text"
              value={lastName}
              onChange={e => setLastName(e.target.value)} />
          </AppCol>
        </AppRow>

        <AddAwardeeButton firstName={firstName} lastName={lastName} onBack={onBack}/>
      </form>
    </div>
  );
}
