import { ReactComponent as BrandSvg } from '../../components/app-svg/moneygram-full-inline.svg';
import { AppLayout } from '../../components/app-layout';
import { AppRow, AppCol } from '../../components/app-grid';
import { AppCta } from '../../components/app-cta';
import { useEffect } from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import {useAccount, useNetwork} from 'wagmi';
export default PageConnect;

function PageConnect() {
  const {isConnected}  = useAccount()
  const {chain} = useNetwork()

  console.log(isConnected)
  if(isConnected && chain.id == 97){
    var r = document.getElementsByTagName('button')
    if(r.length > 0){
      r[0].parentElement.style.display = "none";
    }  
    window.location = "app"
  }

  return (
    <AppLayout name={'connect'}>
      <AppRow left>
        <AppCol middle>
          <BrandSvg />  
        </AppCol>
        <AppCol middle right id="aaa">
            <ConnectButton  accountStatus="address" showBalance="true" label='Connect'/>
        </AppCol>
      </AppRow>
    </AppLayout>
  );
}
