import { useState, Suspense } from 'react';

import { ReactComponent as IconPlisSvg } from '../../components/app-svg/icon-plis.svg';
import { ReactComponent as IconTransfertSvg } from '../../components/app-svg/icon-transfert.svg';
import { ReactComponent as IconLogoSvg } from '../../components/app-svg/icon-moneygram.svg';

import { AppLayout } from '../../components/app-layout';
import { AppRow } from '../../components/app-grid';
import { AppCta } from '../../components/app-cta';

import { SectionCreate } from './section-create';
import { SectionTransfert } from './section-transfert';
import { SectionCertificates } from './section-certificates';

import { TAB_CREATE, TAB_TRANSFERT, TAB_CERTIFICATES } from '../../constants';

import { useEffect } from 'react';
import {useContractRead, useAccount, useContractInfiniteReads , paginatedIndexesConfig, useNetwork} from 'wagmi';
import {COLLECTION_ADDRESS, COLLECTION_ABI} from '../../contracts/Contracts';
import {ethers} from 'ethers'

export default PageLogged;

const tabConfigList = [
  [TAB_CREATE, IconPlisSvg, SectionCreate],
  [TAB_TRANSFERT, IconTransfertSvg, SectionTransfert],
  [TAB_CERTIFICATES, IconLogoSvg, SectionCertificates]
];
let certificates = []


function PageLogged({ initTab }) {
  const {isConnected, address}  = useAccount()
  const {chain} = useNetwork()
  const [certificatesData, setCertificatesData]  = useState([])
  const [loaded, setLoaded]  = useState(false)
  const [refresh, setRefresh]  = useState(false)
  const [activeTab, setActiveTab] = useState(initTab ?? TAB_CREATE);
  const onRedirectTo = (tabName) => setActiveTab(tabName);
  
  useEffect( () => { 
    if(!isConnected || address === undefined || chain.id != 97){
      console.log("Disconnected!")
      window.location = "/"
    } 
  }, [isConnected, chain, address])

  const numCertificates  = useContractRead({
    address: COLLECTION_ADDRESS,
    abi: COLLECTION_ABI,
    functionName: 'numCertificates',
    args: [],
    watch: true,
    onSuccess(data) {
      //console.log('Total Supply Success: ', parseInt(data))
    },
  }) 
  
  /*
  useEffect( () => {  
    
    async function fetchData(){
      const provider = new ethers.providers.JsonRpcProvider("https://data-seed-prebsc-2-s3.binance.org:8545/" ) // provider for signing transaction
      //const provider = new ethers.providers.AlchemyProvider("goerli", 'D6SKBwgFrh2PIEcsKrOnUUKGIiR_Ewc3')
      let erc20 = new ethers.Contract(COLLECTION_ADDRESS, COLLECTION_ABI, provider);
      erc20 = erc20.connect(provider)
      certificates = []
      for(let i = 1 ; i <= parseInt(numCertificates['data']); i++){
        let current = await erc20.getCertificateDetails(i)
        certificates.push(current)
      }
      if(JSON.stringify(certificates) !== JSON.stringify(certificatesData)){
        setCertificatesData(certificates)
      }
    }
    
    if(numCertificates.isSuccess && !loaded ){
      fetchData()
      setLoaded(true)
    }else if(loaded && numCertificates.isSuccess && !refresh){
      setRefresh(true)
      setTimeout(function(){
        fetchData()
        setRefresh(false)
      }, 20000)
      
    }
    
}, [numCertificates]);
*/

  return (
    <AppLayout name={'logged'}>
      <nav className="app-nav__primary">
        <AppRow left spaceBetween>
          {tabConfigList.map(([tabName, TabIcon]) => (
            <AppCta
              key={`tab-${tabName}`}
              active={tabName === activeTab}
              onClick={() => setActiveTab(tabName)}
              type='secondary'
              iconLeft >
              <TabIcon />
              {tabName}
            </AppCta>
          ))}
        </AppRow>
      </nav>

      <Suspense fallback={
      <div>
        <div className="dots">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
      </div>

      <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="9" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -7" result="goo" />
            <feBlend in2="goo" in="SourceGraphic" result="mix" />
          </filter>
        </defs>
      </svg>
      </div>}> 
      {tabConfigList.map(([tabName,, TabSection]) => tabName === activeTab ? <TabSection key={tabName} onRedirectTo={onRedirectTo}
       //numCertificates={numCertificates.isSuccess? parseInt(numCertificates['data']) : 0}  details={certificatesData }/> : false)}
       //numCertificates={parseInt(numCertificates['data'])}  details={certificatesData }/> : false)}
       numCertificates={parseInt(numCertificates['data'])}  /> : false)}
      </Suspense> 
    </AppLayout>
  );
}
